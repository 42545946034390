<template>
  <th-middle-page-wrapper>
    <div class="mb-2 text-base font-bold">
      {{ $t('pages.auth.forgot_password.headline') }}
    </div>

    <div class="mb-8 text-sm" :class="{ italic: !isWhiteLabel }">
      {{ $t('pages.auth.forgot_password.description') }}
    </div>

    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      @submit.prevent="handleResetPassword"
    >
      <!-- Organization -->
      <el-form-item prop="organisation">
        <th-input-title
          :info="$t('pages.auth.forgot_password.organisation.tooltip')"
        >
          {{ $t('pages.auth.forgot_password.organisation.placeholder') }}
          <span class="font-bold"> ({{ $t('common.labels.optional') }}) </span>
        </th-input-title>
        <el-input
          id="organisation"
          v-model="form.organisation"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
          :placeholder="
            $t('pages.auth.forgot_password.organisation.placeholder')
          "
        />
      </el-form-item>

      <!-- Email -->
      <el-form-item
        prop="email"
        :label="$t('pages.auth.forgot_password.placeholder.email')"
      >
        <el-input
          id="email"
          v-model="form.email"
          :placeholder="$t('common.inputs.placeholders.email')"
          type="email"
          auto-complete="email"
        />
      </el-form-item>

      <!-- Submit -->
      <el-button type="primary" native-type="submit" class="w-full mt-4">
        {{ $t('pages.auth.forgot_password.submit') }}
      </el-button>
    </el-form>

    <!-- Login -->
    <div class="flex justify-end text-xs mt-4">
      <router-link to="/login" class="text-th-secondary">
        {{ $t('pages.auth.forgot_password.back') }}
      </router-link>
    </div>
  </th-middle-page-wrapper>
</template>

<script>
import ThMiddlePageWrapper from '@components/middle-page-wrapper'
import auth from '@/plugins/auth'
import { isUnifiedCommerce } from '@/constants'

export default {
  components: {
    ThMiddlePageWrapper
  },

  data() {
    return {
      form: {
        email: '',
        organisation: ''
      },
      rules: {
        email: [
          {
            required: true,
            message: this.$t(
              'pages.settings.users.edit.form.email.validation.errors.required'
            ),
            trigger: 'change'
          },
          {
            min: 5,
            message: this.$t('pages.auth.login.username.validation.length'),
            trigger: 'change'
          }
        ]
      }
    }
  },

  computed: {
    isWhiteLabel() {
      return isUnifiedCommerce()
    }
  },

  methods: {
    async handleResetPassword() {
      try {
        this.loading = true
        await auth.resetPassword({
          email: this.form.email,
          organisation: this.form.organisation
        })

        // Show success
        this.$message({
          type: 'success',
          message: this.$t('pages.auth.forgot_password.success_message')
        })

        // Navigate back
        this.$router.push({ path: '/login' })
      } catch (err) {
        this.$logException(err, {
          message: err.message
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
